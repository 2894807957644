<template>
  <div class="verify">
    <b-card>
      <b-card-body class="text-center">
        <p>Potwierdź swój adres e-mail, klikając przesłany do Ciebie link</p>
        <form @submit.prevent="submit()">
          <button class="btn btn-primary">
            ponownie wyślij link potwierdzający
          </button>
        </form>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {BCard,BCardBody} from "bootstrap-vue";

export default {
  name: 'Verify',
  methods: {
    submit() {
      // post to API to resend email confirmation link
      this.$http.get(`https://api.${this.$store.state.domain}/api/email/resend`)
          .then(response => {
            this.$bvToast.toast(response.data.message,{title:'resend',variant: 'success'});
          })
          .catch(response => {
            this.$bvToast.toast(response.data, {
              title: 'resend',
              solid: true,
              variant: 'danger',
            });
          })
    }
  },
  components: {
    BCard,BCardBody,
  },
};
</script>
